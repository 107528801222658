import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex bd-highlight align-center flex-wrap"},[_vm._v(" Retenção da carteira - Regional ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap mt-5 pb-0"},[_c(VRow,{staticClass:"mb-3"},[_c(VCol,{staticClass:"pb-1",attrs:{"cols":"12","md":"4"}},[_c(VAutocomplete,{staticClass:"pb-1",attrs:{"items":_vm.listRegionsItems,"item-text":"name","item-value":"name","label":"Região","outlined":"","dense":"","hide-details":"","cols":"12","md":"3","sm":"4","lg":"3"},on:{"input":_vm.onInput},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataTable,"group-by":"region","loading-text":"Carregando dados...","loading":_vm.loading,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',{class:_vm.classNoDataText},[_vm._v(_vm._s(_vm.noDataText))])]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{attrs:{"colspan":"10"}},[_vm._v(" "+_vm._s(items[0].region)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }